import React from 'react'
import Img from 'gatsby-image'

export default () => (
  <img
    className="h-16 object-scale-down	 w-24 md:w-48 object-cover object-left"
    src="/images/Untitled.png"
    alt="Logo Sherpaindoproject"
  />
  // <Img
  //   fluid="/images/logows.png"
  //   className="h-auto w-24 md:w-48 object-cover object-left"
  //   alt="Logo Sherpa Indo Kids"
  // />
)
