import React, { Component } from 'react'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import Logo from './LogoWS'
import { Menu, X, Linkedin } from 'react-feather'

export class Navigation extends Component {
  state = {
    active: false,
    activeSubNav: false,
    currentPath: false
  }

  // componentDidMount() {
  //   this.setState({ currentPath: this.props.location.pathname })
  // }

  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = (subNav) =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })

  render() {
    const { active } = this.state
    let { color, small } = this.props

    return (
      <nav
        className={`flex items-center justify-between flex-wrap p-1 shadow bg-orange-sherpa nav-shadow sticky w-full z-10 pin-t `}
      >
        <div class="flex items-center flex-no-shrink text-white mr-6">
          <a
            class="text-white no-underline hover:text-white hover:no-underline"
            href="/"
          >
            <Logo></Logo>
          </a>
        </div>

        <div class="block lg:hidden">
          <button
            className="flex items-center px-3 py-2 text-grey hover:text-white "
            onClick={this.handleMenuToggle}
          >
            {active ? <X /> : <Menu />}
          </button>
        </div>

        <div
          className={`${
            active ? '' : 'hidden'
          } w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block pt-6 lg:pt-0 text-lg `}
          id="nav-content"
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center ">
            <li className="mr-3">
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 mr-4 text-black hover:text-white"
                to="/"
                activeStyle={{
                  color: '#ff9000'
                }}
              >
                Home
              </Link>
            </li>
            <li className="mr-3">
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 mr-4 text-black hover:text-white"
                to="/project/"
                activeStyle={{
                  color: '#ff9000'
                }}
              >
                Project
              </Link>
            </li>
            <ul class="mr-3 group">
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 mr-4 text-black hover:text-white"
                to="#"
                activeStyle={{
                  color: '#ff9000'
                }}
              >
                Services
              </Link>
              <li class="pl-2 bg-teal-light text-black w-full lg:absolute lg:w-auto hidden invisible group-hover:visible group-hover:block ">
                <Link
                  to="/service/website"
                  class="block no-underline px-6 p-4 hover:underline hover:text-orange-sherpa"
                  activeStyle={{
                    color: '#ff9000'
                  }}
                >
                  Website
                </Link>
                <Link
                  to="/service/workshop/kids"
                  class="block no-underline px-6 p-4 hover:underline hover:text-orange-sherpa"
                  activeStyle={{
                    color: '#ff9000'
                  }}
                >
                  Sherpa Indo Kids
                </Link>
              </li>
            </ul>

            <li className="mr-3">
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 mr-4 text-black hover:text-white"
                to="/blog/"
                activeStyle={{
                  color: '#ff9000'
                }}
              >
                Blog
              </Link>
            </li>

            <li className="mr-3">
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 mr-4 text-black hover:text-white"
                to="/contact/"
                activeStyle={{
                  color: '#ff9000'
                }}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default ({ subNav }) => (
  <Location>{(route) => <Navigation subNav={subNav} {...route} />}</Location>
)
