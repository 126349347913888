import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import Nav from '../components/NavWS'

import animationData from '../img/1.json'
import animationLaptop from '../img/2.json'
import animationCommunity from '../img/3.json'
import { Player } from '@lottiefiles/react-lottie-player'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
// Export Template for use in CMS preview
export class WorkshopKidsTemplate extends Component {
  state = {
    active: false,
    activeSubNav: false,
    currentPath: false
  }

  render() {
    const { title, subtitle, carousel, featuredImage, minat } = this.props
    

    // let progress = 0
    // let invervalSpeed = 10
    // let incrementSpeed = 1
    // let progressInterval
    

    return (
      <>
        <Nav color small />
        <div class=" flex flex-wrap md items-center md:h-screen mb-0 md:mb-16 bg-orange-sherpa">
          <div class="bg-orange-sherpa md:h-full w-full md:w-1/2 m-auto flex flex-wrap flex-col md:flex-row items-center justify-center">
            <div class="md:mx-32 mt-0 md:-mt-28 h-auto pb-8 md:pb-0 text-center lg:text-left w-full  justify-center text-center pt-4 ">
              <h1 class="text-ref-heading	font-bold	">{title}</h1>

              <div class="flex mt-12">
                <div class="pr-4">
                  <p class="text-xl text-gray-900 ">{subtitle}</p>
                </div>
              </div>
              <a
                href="https://api.whatsapp.com/send?phone=6282216101222&text=Terima+kasih+telah+menghubungi+team+Sherpa+Indo+Project.+Silakan+ketik+info+%F0%9F%98%8A"
                target="_blank"
              >
                <button class="bg-orange-sherpa mt-12 px-4 py-2 font-bold text-white bg-test-2 rounded-full hover:shadow focus:outline-none focus:shadow-outline">
                  {' '}
                  Daftar Sekarang{' '}
                </button>{' '}
              </a>
            </div>
          </div>
          <div class="bg-orange-sherpa md:h-full w-full md:w-1/2">
            <Carousel
              showStatus={false}
              showThumbs={false}
              showIndicators={false}
              showArrows={false}
              swipeable={false}
              infiniteLoop
              autoPlay
              transitionTime={100}
            >
              {carousel.map(({ featuredImage, title, subtitle }) => (
                <div>
                  <Img
                    fluid={featuredImage.childImageSharp.fluid}
                    className="h-auto m-0"
                    alt={`featured image thumbnail for post ${title}`}
                  />

                  <div class="flex flex-wrap justify-center text-center w-full">
                    <div class="md:mt-4 mt-0 p-4 md:p-0">
                      <h2 className="mb-4  text-bold">{title}</h2>
                      <p>{subtitle}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
            {/* <h1>Progress Bar</h1>
            <div class="h-3 relative max-w-xl rounded-full overflow-hidden">
              <div class="w-full h-full bg-gray-200 absolute"></div>
              <div id="bar" class="h-full bg-green-500 relative w-0"></div>
            </div> */}
          </div>
        </div>

        <section className=" pb-8 md:px-24 w-full">
          <div class="max-w-screen-xl px-8 grid gap-8 grid-cols-1 md:grid-cols-2  mx-auto  text-gray-900 ">
            <div class="flex flex-col justify-between">
              <div>
                <div className="float-left w-full">
                  <Player
                    autoplay
                    loop
                    src={animationData}
                    style={{ height: 'auto', width: '500' }}
                  ></Player>
                </div>
              </div>
            </div>
            <div className="w-full self-center px-4 mr-auto ml-auto">
              <h3 className="text-ref-heading-3 mb-2 font-bold text-color-ref-heading">
                Manfaat bergabung dengan Sherpa Indo Kids
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-color-ref-heading">
                Sherpa Indo Kids adalah ruang bagi sahabat cilik agar dapat
                mengembangkan potensi mulai dari{' '}
                <i>intelligence, creativity, self-confidence, or enthusiasm</i>{' '}
                dengan penyajian materi yang diminati
              </p>
            </div>
          </div>
        </section>

        <section className=" md:px-24 py-16 w-full">
          <div class="max-w-screen-xl px-12 md:px-16 2xl:px-64 grid gap-8 grid-cols-1 text-gray-900">
            <h2 className="text-ref-heading-3 text-heading-3 mb-2 font-bold pb-8">
              Temukan minatmu
            </h2>
            {minat.map(({ featuredImage, title }) => (
              <div class="card bg-orange-sherpa shadow-2xl w-full md:w-80 h-full  hover:shadow-none relative flex flex-col rounded z-1">
                <Img
                  fluid={featuredImage.childImageSharp.fluid}
                  className="h-full w-auto  absolute top-0 z-0"
                  alt={`featured image thumbnail for post ${title}`}
                />
                <div class="absolute my-auto left-0 bottom-12 pl-4 text-center ">
                  <h3 className="font-bold text-ref-heading-3">{title}</h3>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className=" pb-8 md:px-24 w-full" style={{ direction: 'rtl' }}>
          <div class="max-w-screen-xl  px-8 grid gap-8 grid-cols-1 md:grid-cols-2   mx-auto  text-color-ref-heading ">
            <div class="flex flex-col justify-between">
              <div>
                <div className="float-left w-full">
                  <Player
                    autoplay
                    loop
                    src={animationLaptop}
                    style={{ height: 'auto', width: '500' }}
                  ></Player>
                </div>
              </div>
            </div>
            <div className="w-full  px-4 mr-auto ml-auto self-center text-left">
              <h3 className="text-ref-heading-3 text-heading-3 mb-2 font-bold ">
                Metode pembelajaran Sherpa Indo Kids
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-color-ref-heading">
                Metode pembelajaran Sherpa Indo Kids dikembangkan berdasarkan
                kurikulum CSFirst yang telah memenuhi standarisasi pendidikan
                Computer Science Teachers Association, International Society for Technology in Education, Next Generation Science Standards Engineering, dan Common Core State Standards
              </p>
            </div>
          </div>
        </section>
        <section className=" pb-8 md:px-24 w-full">
          <div class="max-w-screen-xl px-8 grid gap-8 grid-cols-1 md:grid-cols-2 mx-auto  text-gray-900 ">
            <div class="flex flex-col justify-between">
              <div>
                <div className="float-left w-full">
                  <Player
                    autoplay
                    loop
                    src={animationCommunity}
                    style={{ height: 'auto', width: '500' }}
                  ></Player>
                </div>
              </div>
            </div>
            <div className="w-full  px-4 mr-auto ml-auto self-center">
              <h3 className="text-ref-heading-3 mb-2 font-bold text-color-ref-heading">
                Komunitas Sherpa Indo Kids
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-color-ref-heading">
                Sahabat cilik Sherpa Indo Kids secara otomatis tergabung dalam
                komunitas Sherpa Indo Kids untuk dapat saling mengenal dan
                berbagi informasi dalam kegiatan komunitas
              </p>
            </div>
          </div>
        </section>
        <div class="mx-auto">
          <div class="flex justify-center my-12 bg-orange-sherpa">
            <div class="w-full flex  md:flex-nowrap flex-wrap">
              <div class="w-full h-auto bg-gray-400 block bg-cover rounded-l-lg">
                <Img
                  fluid={featuredImage.childImageSharp.fluid}
                  className="h-auto m-0"
                  alt={`featured image thumbnail for post ${title}`}
                />
              </div>
              <div class="w-full  p-5 rounded-lg lg:rounded-l-none self-center">
                <div class="px-8  text-center">
                  <h1 class=" mb-12 text-4xl font-bold">Ayo Daftar</h1>
                  <p class="mb-12 text-xl text-gray-900">
                    Dapatkan materi dan pola pembelajaran yang menyenangkan
                    secara online maupun offline untuk bekal sahabat cilik di
                    masa depan
                  </p>
                </div>
                <form class="px-8 rounded">
                  <div class="mb-6 text-center">
                    <button
                      class=" px-4 py-2 font-bold text-white bg-test-2 rounded-full hover:shadow focus:outline-none focus:shadow-outline"
                      type="button"
                    >
                      <a
                        href="https://api.whatsapp.com/send?phone=6282216101222&text=Terima+kasih+telah+menghubungi+team+Sherpa+Indo+Project.+Silakan+ketik+info+%F0%9F%98%8A"
                        target="_blank"
                      >
                        {' '}
                        Daftar Sekarang{' '}
                      </a>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const WorkshopKids = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <WorkshopKidsTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default WorkshopKids

export const pageQuery = graphql`
  query WorkshopKids($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        carousel {
          title
          subtitle
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 98) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        minat {
          title
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 98) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 98) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
